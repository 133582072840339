#site-footer{
	background: $normal;
	padding: $mrg*1.5 $mrg;
	color: #FFF;
	font-size: 13px;
	@media screen and (max-width:$phone){
		padding: $mrg-sp*2 $mrg-sp;
	}
	a{
		color: #FFF;
		&:hover{
			opacity: .64;
		}
	}
	.fnav{
        text-align: center;
		@media screen and (max-width:$phone){
			border: rgba(#000,.24) 1px solid;
		}
        li{
            list-style: none;
            display: inline-block;
			@media screen and (max-width:$phone){
				display: block;
				&:last-child{
					a{
						border-bottom: none;
					}
				}
				a{
					padding: $mrg-sp;
					display: block;
					position: relative;
					text-align: left;
					border-bottom: rgba(#000,.24) 1px solid;
					&:after{
						content: "\f054";
						font-family: "Font Awesome 5 free";
						opacity: .16;
						font-weight: 900;
						position: absolute;
						top: 50%;
						right: $mrg-sp;
						transform: translateY(-50%);
					}
				}
			}
			&:after{
				content: '|';
				display: inline-block;
				margin: 0 40px;
				opacity: .08;
				@media screen and (max-width:$phone){
					display: none;
				}
			}
			&:last-child{
				&:after{
					display: none;
				}
			}
        }
	}
	small.copy{
		margin: 48px 0 0;
		text-align: center;
		display: block;
		font-size: 11px;
		letter-spacing: .12em;
		opacity: .88;
		@media screen and (max-width:$phone){
			margin: $mrg-sp*2 0 0;	
		}
	}
}