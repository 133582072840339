#site-header{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	color: #FFF;
	z-index: 1000;
    @include transition(200ms);
    &:hover{
        background: rgba(#000,.12);
        .inner{
            margin: 0;
            padding: 0 $mrg;
			@media screen and (max-width:$phone){
				padding: 0 $mrg-sp;	
			}
        }
    }
	.inner{
		@include flex();
		align-items: center;
		margin: 0 $mrg;
		border-bottom: rgba(#FFF,.24) 1px solid;
        @include transition(200ms);
		@media screen and (max-width:$phone){
			margin: 0 $mrg-sp;
		}
	}
    a{
     	color: #FFF;
     }
	.logo{
		background: url("../../../images/common/logo_w.png") no-repeat;
		background-size: cover;
		width: 140px;
		height: 41px;
		@media screen and (max-width:$phone){
			width: 120px;
			height: 35px;
			transform: translateY(-2px);
		}
	}
	.gnavi{
		@extend .clearfix;
		li{
			list-style: none;
			float: left;
			font-size: 15px;
			font-weight: 600;
			letter-spacing: .12em;
			margin: 0 0 0 6vw;
			&:first-child{
				margin-left: 0;
			}
			@media screen and (max-width:$phone){
				display: none;
				&:nth-child(2){
					display: block;
					margin: 0;
				}
			}
			a{
				display: block;
				padding: 32px 0;
				position: relative;
				@media screen and (max-width:$phone){
					padding: 24px 0;	
				}
				&:hover{
                    &:before{
                        width: 64px;
                    }
                }
				&:before{
					content: '';
					width: 0px;
					height: 1px;
					position: absolute;
					left: 50%;
					bottom: 0;
					transform: translateX(-50%);
					background: #FFF;
					@include transition(200ms);
				}
			}
		}
	}
	.contact{
		font-size: 24px;
		a{
			@include transition(120ms);
			display: block;
			&:hover{
				transform: scale(1.3);
			}
		}
	}
	
	.contact-overlay{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: rgba(#000,.8);
		z-index: 5000;
		@include flex();
		align-items: center;
		justify-content: center;
		text-align: center;
		.contactbox{
			padding: $mrg/2 $mrg;
			background: #FFF;
			color: $normal;
			border-radius: 4px;
			position: relative;
			@media screen and (max-width:$phone){
				padding: $mrg-sp;
				width: calc(100% - #{$mrg-sp * 2});
				height: calc(100% - #{$mrg-sp * 2});
				margin: $mrg-sp;
				@include flex();
				align-items: center;
				justify-content: center;
			}
			i.close{
				position: absolute;
				top: 12px;
				right: 12px;
				$size:32px;
				width: $size;
				height: $size;
				cursor: pointer;
				
				&:hover{
					&:before,
					&:after{
						transform: rotate(0deg);
					}
				}
				&:before,
				&:after{
					content: '';
					width: 100%;
					height: 1px;
					background: $normal;
					position: absolute;
					top: 50%;
					left: 0;
					transform: rotate(45deg);
					@include transition(200ms);
				}
				&:after{
					transform: rotate(-45deg);
				}
			}
			a{
				color: $normal;
			}
			dl{
				margin: 8px 0 0;
				dt{
					font-size: 14px;
					margin: 0 0 10px;
				}
				dd{
					font-size: 14px;
					&.tel{
						@include self;
						line-height: 1;
						font-size: 36px;
						margin: 0 0 12px;
						@media screen and (max-width:$phone){
							font-size: 30px;	
						}
						a{
							&:hover{
								transform: scale(1);
							}
						}
					}
				}
			}
		}
	}
}

