@mixin woo-h(){
	border-bottom: $border 1px solid;
	position: relative;
	padding: 12px;
	padding-left: 20px;
	font-size: 22px;
	line-height: 1;
	font-weight: 700;
	margin: 0 0 16px;
	@media screen and (max-width: $phone){
		font-size: 18px;	
	}
	small{
		display: inline-block;
		margin: 0 0 0 20px;
		font-size: 13px;
	}
	&:after{
		content: '';
		width: 5px;
		height: 70%;
		background: $btn-color;
		border-radius: 3px;
		display: block;
		position: absolute;
		top: 15%;
		left: 0;
	}
}

@mixin woo-input(){
	input[type=text],
	input[type=email],
	input[type=tel],
	input[type=password],
	input[type=number],
	select,
	textarea{
		@include font();
		font-size: 13px;
		color: $normal;
		border: $border 1px solid;
		background: #FFF;
		outline: none;
		padding: 8px;
		width: 100%;
	}
	option{
		background: #FFF;
	}
}

@mixin woo-notices{
	width: 100%;
	margin: 0 0 24px;
	font-size: 16px;
	color: $normal;
	border-radius: 3px;
	padding: 10px 20px;
	background: $gray;
	position: relative;
	text-align: left;
	overflow: hidden;
	border: none;
	@media screen and (max-width: $phone){
		font-size: 13px;
		padding: 8px 15px;
	}
	&:before{
		display: none;
	}
	a{
		&:hover{
			text-decoration: underline;
		}
		&.button{
			@media screen and (min-width: $phone + 1px){
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
				color: #FFF;
				padding: 0 20px;
				height: 100%;
				background: $btn-color;
				@include flex();
				align-items: center;
				&:hover{
					background: lighten($btn-color, 4%);
					text-decoration: none;
				}
			}
			@media screen and (max-width: $phone){
				display: block;
				float: none;
				background: $btn-color;
				color: #FFF;
				padding: 4px 15px;
				margin: 0 0 4px;
				border-radius: 3px;
			}
		}
	}
}