#bread{
	height: 400px;
	background-position: center;
	background-size: cover;
	position: relative;
	@include flex();
	align-items: center;
	justify-content: center;
	color: #FFF;
	overflow: hidden;
	@media screen and (max-width:$phone){
		min-height: 1px;
		height: 60vh;
	}
	a{
		color: #FFF;
	}
	&:before{
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: #000;
		opacity: .08;
	}
	h1{
		line-height: 1;
		font-size: 15px;
		text-align: center;
		padding: 32px 0 0;
		&:before{
			@include self;
			content: attr(data-eng);
			display: block;
			font-size: 32px;
			letter-spacing: .24em;
			margin: 0 0 12px;
		}
	}
	nav{
		position: absolute;
		left: 64px;
		bottom: 24px;
		@media screen and (max-width:$phone){
			left: $mrg-sp;	
			bottom: $mrg-sp;	
		}
		ol{
			@extend .clearfix;
			li{
				list-style: none;
				float: left;
				font-size: 12px;
				&:after{
					content: "\f054";
					font-family: "Font Awesome 5 Free";
					font-weight: 900;
					transform: scale(.5);
					display: inline-block;
					margin: 0 12px;
				}
				&:last-child{
					&:after{
						display: none;
					}
				}
			}
		}
	}
	.off{
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 100%;
		background: #FFF;
		z-index: 20;
	}
}