.site-woo{
	.woocommerce-MyAccount-navigation{
		margin: 0 0 24px;
		ul{
			@include flex();
			border-radius: 3px;
			overflow: hidden;
			li{
				width: calc(100% / 6 - 1px);
				@media screen and (max-width: $phone){
					width: 50%;
					border-right: $border 1px solid;
					border-top: $border 1px solid;
					&:nth-child(-n+2){
						border-top: 0;
					}
					&:nth-child(2n+2){
						border-right: 0;
					}
				}
				&.is-active{
					a{
						background: $btn-color;
					}
				}
				a{
					background: $dark-color;
					color: #FFF;
					display: block;
					padding: 8px 15px;
					text-align: center;
					font-size: 15px;
					&:hover{
						background: $btn-color;
					}
				}
			}
		}
	}
	.account-orders-table{
		.button{
			background: $btn-color;
            color: #FFF;
			&:hover{
				background: darken($btn-color,4%);
			}
		}
	}
	.woocommerce-Address-title{
		margin: 20px 0;
		h3{
			@include woo-h;
		}
		.edit{
			background: $btn-color;
			border-radius: 3px;
			color: #FFF;
			text-align: center;
			display: inline-block;
			padding: 8px 24px;
			&:hover{
				background: $dark-color;
			}
		}
	}
	address{
		font-style: normal;
	}
	.woocommerce-EditAccountForm{
		.woocommerce-form-row{
			margin: 0 0 15px;
			label{
				display: block;
				margin: 0 0 5px;
			}
			input{
				& + span{
					display: block;
					margin: 5px 0 0;
					font-style: normal;
				}
			}
			.show-password-input{
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
				margin: 0;
			}
		}
		.woocommerce-Button{
			background: $btn-color;
			color: #FFF;
			&:hover{
				background: darken($btn-color,4%);
			}
		}
	}
	
	.woocommerce-form-login,
	.woocommerce-form-register{
		border: none !important;
		background: $gray;
		margin-top: 10px !important;
		.woocommerce-form-row{
			label{
				display: block;
				margin: 0 0 6px;
			}
			.show-password-input{
				right: 15px;
				top: 50%;
				transform: translateY(-50%);
				margin: 0;
			}
		}
        .woocommerce-button{
			background: $btn-color;
            color: #FFF;
            &:hover{
                background: darken($btn-color,4%);
            }
        }
		.woocommerce-privacy-policy-text{
			margin: 17px 0;
			font-size: 13.5px;
			text-align: justify;
		}
		.woocommerce-form-login{
			&__rememberme{
				display: inline-flex;
				align-items: center;
				input{
					display: inline-block;
					margin: 0 8px 0 0;
				}
			}
		}
		.woocommerce-LostPassword{
			&.lost_password{
				a{
					color: $btn-color;
					text-decoration: underline;
				}
			}
		}
	}
	.lost_reset_password{
		.woocommerce-form-row{
			margin: 15px 0 0;
			label{
				display: block;
				margin: 0 0 4px;
			}
		}
        .show-password-input{
            right: 15px;
            top: 50%;
            transform: translateY(-50%);
            margin: 0;
        }
		.woocommerce-Button{
			background: $btn-color;
			color: #FFF;
			&:hover{
				background: darken($btn-color,4%);
			}
		}
	}
	.woocommerce-message{
		@include woo-notices();
	}
}