@charset "utf-8";

@import "module/reset";
@import "module/module";


/* BASE COLOR
------------------------------------------------------------*/
$normal:#444;
$link:#D02;
$border:#e8e8e8;


/* SITE SETTING
------------------------------------------------------------*/
$width:1400px;

$mrg: 80px;
$mrg-sp:16px;

$tab: 1024px;
$phone: 768px;


@media screen and (min-width:$tab+1px){
	@include default(
		$width, //サイト幅
		14px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@media screen and (max-width:$tab){
	@include default(
		auto, //サイト幅
		13px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}
@media screen and (max-width:$phone){
	@include default(
		auto, //サイト幅
		12px, //フォントサイズ
		$normal, //フォントカラー
		$link, //リンクカラー
		#FFF //ボディ背景
	);
}

@import "common/header";
@import "common/footer";
@import "common/bread";
@import "page/index";
@import "page/about";
@import "page/guide";

$btn-color : #888;
$dark-color : #888;
$star-color : #f7ab03;
$gray: #f5f5f6;

@import "woo/common";
@import "woo/account";
@import "woo/cart";
@import "woo/pay";
@import "woo/shop";

#site-wrapper,#site-footer{
    position: relative;
	.display-min{
		max-width: 1024px;
	}
}

.site-module{
	&.wrap{
		padding: 96px $mrg;
		@media screen and (max-width:$phone - 1px){
			padding: $mrg-sp*3 $mrg-sp;	
		}
		&.no-top{
			padding-top: 0;
		}
		&.no-bottom{
			padding-bottom: 0;
		}
	}
	&.bg{
		background: #f2f2f2;
	}
	h2.ec-title{
		@include min();
		text-align: center;
		font-size: 32px;
		line-height: 1;
		margin: 0 0 32px;
		&:after{
			content: attr(data-eng);
			font-size: 12px;
			display: block;
			margin: 10px 0 0;
		}
		@media screen and (max-width:$phone){
			font-size: 22px;
			margin: 0 0 16px;
		}
	}
}

.scroll-img{
	position: relative;
	$ease-transition: cubic-bezier(0.86, 0, 0.07, 1);
	$speed: 600ms;
	&:before,
	&:after{
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 100%;
	}
	&:before{
		background: $normal;
		@include transition($speed , $delay:$speed , $ease:$ease-transition);
	}
	&:after{
		background: #f2f2f2;
		@include transition($speed , $ease: $ease-transition);
	}
	&.__active{
		&:before{
			width: 0;
		}
		&:after{
			width: 0;
		}
	}
}

.scale-enter-active, .scale-leave-active {
	transition: 600ms;
}
.scale-enter, .scale-leave-to {
	opacity: 0;
	transform: scale(1.1);
}

#loading{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: #FFF;
	@include self;
	@include flex();
	align-items: center;
	justify-content: center;
	font-size: 24px;
	overflow: hidden;
	@include transition(500ms , $delay:1000ms);
	&.active{
		height: 0;
		#bar{
			width: 0 !important;
			@include transition(400ms , $delay:400ms);
		}
		#percent{
			animation: none;
			opacity: 0 !important;
			@include transition(300ms);
		}
	}
	#bar{
		width: 0;
		height: 1px;
		background: rgba($normal,.32);;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX(-50%);
	}
	#percent{
		display: inline-block;
		position: relative;
		z-index: 2;
		white-space: nowrap;
		background: #FFF;
		padding: 0 24px;
		animation: sc 800ms infinite cubic-bezier(0.645, 0.045, 0.355, 1);
		@keyframes sc{
			0%{
				color: rgba($normal,.64);
			}
			50%{
				color: rgba($normal,.32);
			}
			100%{
				color: rgba($normal,.64);
			}
		}
	}
}

._move{
	$time: 1800ms;
    position: fixed;
    top: 100%;
    left: 0;
    width: 100%;
    height: 100%;
	min-height: 480px;
    z-index: 8000;
	background: #FFF;
    &.moved{
		animation: anime $time cubic-bezier(0.645, 0.045, 0.355, 1);
		@keyframes anime{
			0%{
				top: 100%;
			}
			30%{
				top: 0;

			}
			70%{
				top: 0;

			}
			100%{
				top: -100%;
			}
		}
	}
}
