#site-index{
	.flexer{
		width: 100vw;
		height: 100vh;
		min-height: 500px;
        overflow: hidden;
		position: relative;
		@include flex();
		align-items:flex-end;
		justify-content: flex-start;
        .swiper-container{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        .tilt {
            overflow: hidden;
            position: fixed;
            width: 100%;
            height: 100%;
            transform: scale(1.08);
			background: #000;
            .tilt__back,
            .tilt__front {
                width: 100%;
                height: 100%;
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-size: cover;
                -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
            }
            .tilt__back {
                position: relative;
				opacity: .88 !important;
            }
            .tilt__front {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
		h1{
			@include self;
            pointer-events: none;
			text-align: left;
			color: #FFF;
			position: relative;
			z-index: 2;
			line-height: 1.1;
			letter-spacing: .02em;
			font-weight: 400;
			font-size: calc(56px + 22 * (100vw - 450px)/ 900);
			white-space: nowrap;
			padding: 0 0 48px $mrg;
			@media screen and (max-width:$phone){
				font-size: 32px;
				line-height: 1.1;
				padding: 0 0 $mrg-sp + 4px $mrg-sp;
			}
		}
		.copy{
			height: 100%;
			position: absolute;
			top: 0;
			right: $mrg;
			font-size: calc(9px + 3 * (100vw - 450px)/ 900);
            z-index: 2;
            line-height: 1;
            pointer-events: none;
			color: #FFF;
			-webkit-writing-mode: vertical-rl;
			-moz-writing-mode: vertical-rl;
			-ms-writing-mode: tb-rl;
			writing-mode: vertical-rl;
			white-space: nowrap;
			letter-spacing: .2em;
			text-align: center;
			@media screen and (max-width:$phone){
				right: $mrg-sp;	
			}
		}
        .swiper-pagination{
            position: absolute;
            width: auto;
            right: $mrg;
            text-align: right;
            bottom: 48px;
			@media screen and (max-width:$phone){
				right: $mrg-sp;	
				bottom: $mrg-sp;	
			}
            .swiper-pagination-bullet{
                width: 11px;
                height: 11px;
                border: #FFF 1px solid;
                background: rgba(#FFF,0);
                opacity: 1;
                margin: 0 0 0 12px;
                &.swiper-pagination-bullet-active{
                    background: #FFF;
                }
            }
        }
	}
}