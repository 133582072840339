.site-guide{
	h3.borders{
		border-bottom: $border 1px solid;
		position: relative;
		padding: 12px;
		padding-left: 20px;
		font-size: 22px;
		line-height: 1;
		font-weight: 700;
		margin: 0 0 16px;
		@media screen and (max-width: $phone){
			font-size: 18px;	
		}
		small{
			display: inline-block;
			margin: 0 0 0 20px;
			font-size: 13px;
		}
		&:after{
			content: '';
			width: 5px;
			height: 70%;
			background: $normal;
			border-radius: 3px;
			display: block;
			position: absolute;
			top: 15%;
			left: 0;
		}
	}
	.sec{
		margin: 0 0 64px;
		&:last-child{
			margin: 0;
		}
		&__list{
			border: $border 1px solid;
			border-radius: 5px;
			padding: 20px;
			margin: 16px 0 0;
			@media screen and (max-width: $phone){
				padding: 15px;	
			}
			>li{
				margin: 0 0 20px;
				padding: 0 0 20px;
				border-bottom: $border 1px solid;
				@media screen and (max-width: $phone){
					margin: 0 0 15px;
					padding: 0 0 15px;
				}
				&:last-child{
					margin: 0;
					padding: 0;
					border: none;
				}
				>dl{
					dt{
						font-size: 18px;
						font-weight: 700;
						@media screen and (max-width: $phone){
							font-size: 16px;	
						}
					}
					dd{
						margin: 0 0 8px;
					}
				}
				h4{
					font-size: 16px;
					font-weight: 700;
					@media screen and (max-width: $phone){
						font-size: 14px;	
					}
					&:before{
						content: '■';
						color: $normal;
						display: inline-block;
						margin: 0 3px 0 0;
					}
				}
				.mini{
					margin: 16px 0 0;
					background: #f5f5f6;
					border-radius: 5px;
					padding: 20px;
					@media screen and (max-width: $phone){
						padding: 15px;	
					}
				}
				.important{
					border: $border 1px dashed;
					padding: 20px;
					border-radius: 5px;
					margin: 20px 0 0;
					@media screen and (max-width: $phone){
						padding: 15px;
						margin: 15px 0 0;
					}
					h4{
						color: $normal;
						&:before{
							color: $normal;
						}
					}
					&__text{
						color: $normal;
						font-weight: 700;
					}
				}
				.atten{
					background: #f5f5f6;
					padding: 20px;
					border-radius: 5px;
					margin: 20px 0 0;
					@media screen and (max-width: $phone){
						padding: 15px;
						margin: 15px 0 0;
					}
				}
				.tablestyle{
					margin: 20px 0 0;
					@media screen and (max-width: $phone){
						margin: 15px 0 0;	
					}
					tbody{
						tr{
							th{
								width: 70%;
								@media screen and (max-width: $phone){
									width: 100%;	
								}
							}
							td{
								color: $normal;
							}
						}
					}
				}
			}
		}
	}
}