.site-woo{
	.woocommerce-info,
	.woocommerce-error{
		@include woo-notices();
	}
    .shop_table{
        border: $border 1px solid;
        border-collapse: collapse;
        width: 100%;
		@media screen and (max-width: $phone){
			border: none;
			border-top: $border 1px solid !important;
			border-radius: 0 !important;
		}
        thead{
            background: $gray;
        }
        thead,
        tbody,
		tfoot{
            tr{
                th,td{
                    padding: 15px;
                    vertical-align: middle;
                    text-align: left;
                    border: $border 1px solid;
                    font-size: 15px;
					@media screen and (max-width: $phone){
						border-top: 0;	
					}
                    &.product-thumbnail{
                        width: 80px;
                        img{
                            width: 100%;
                            height: auto;
                        }
                    }
                    &.product-remove{
                        text-align: center;
                        a{
                            color: $btn-color;
                            font-size: 17px;
                        }
                    }
                }
                th{

                }
                td{
                    &.product-name{
                        width: 200px;
                        font-size: 17px;
                        font-weight: 700;
						@media screen and (max-width: $phone){
							width: auto;	
						}
                        a{
                            color: $normal;
                            text-decoration: underline;
                        }
                    }
                    .quantity{
                        @include flex();
                        justify-content: flex-start;
                        align-items: center;
                        input{
                            width: auto;
                            margin: 0 0 0 15px;
                        }
                    }
                    .coupon{
                        @include flex();
                        align-items: center;
                        justify-content: flex-start;
                        > *{
                            margin-right: 15px;
                        }
                        #coupon_code{
                            width: auto;
							@media screen and (max-width: $phone){
								width: 130px;
							}
                        }
                        button{
                            @include font();
                            border: $btn-color 1px solid;
                            background: none;
                            color: $btn-color;
                            float: none;
                            &:hover{
								background: $btn-color;
                        		color: #FFF;
                                cursor: pointer;
                            }
                        }
                    }
                    button{
                        @include font();
                        border: none;
						background: $btn-color;
                        color: #FFF;
                        font-size: 16px;
                        padding: 10px 16px;
                        border-radius: 3px;
                        float: right;
                        cursor: pointer;
                        &:hover{
                            background: darken($btn-color,4%);
                        }
                    }
					.shipping-calculator-button{
						text-decoration: underline;
						color: $btn-color;
					}
                }
            }
        }
    }
	.cart-collaterals{
		margin: 48px 0 0;
		.cart_totals{
			width: auto;
			float: none;
		}
		.cart_totals{
			h2{
				@include woo-h();
			}
		}
		.cart-subtotal{
			th{
				min-width: 100px;
			}
		}
		.shipping-calculator-form{
			margin: 24px 0 0;
			@include flex();
			.form-row{
				width: calc(25% - 15px);
				margin: 0 0 15px;
			}
		}
	}
	.wc-proceed-to-checkout{
		text-align: center;
		margin: 40px 0 0;
		a{
			@include font();
			border: none;
			background: $btn-color !important;
			color: $normal;
			font-size: 18px;
			padding: 12px 48px;
			border-radius: 3px;
			cursor: pointer;
			display: inline-block;
			&:hover{
				background: $dark-color !important;
			}
		}
	}
	#payment{
		background: $gray;
		border: $border 1px solid;
		ul.wc_payment_methods{
			border-color: $border;
			.wc_payment_method{
                >label{
                    color: $btn-color;
                }
				.payment_box{
					background: #FFF;
					&:before{
						border-color: #FFF;
						border-right-color: transparent;
						border-left-color: transparent;
						border-top-color: transparent;
					}
					>p{
						color: $normal;
					}
				}
			}
		}
		.button{
			background: $btn-color;
			@media screen and (max-width: $phone){
				padding: 15px;
				font-size: 17px;
				margin: 20px 0 0;
				margin-bottom: 0 !important;
			}
			&:hover{
				background: $dark-color;
			}
		}
	}
	a.remove{
		color: $btn-color !important;
	}
	.return-to-shop{
		text-align: center;
		a{
			&.button{
				background: $btn-color;
				color: #FFF;
			}
		}
	}
}