.site-woo{
	@include woo-input();
	.page-title{
        border-bottom: $border 1px solid;
		position: relative;
        padding: 12px;
		padding-left: 20px;
        font-size: 22px;
		line-height: 1;
        font-weight: 700;
		margin: 0 0 16px;
		@media screen and (max-width: $phone){
			font-size: 18px;	
		}
		&:after{
			content: '';
			width: 5px;
			height: 70%;
			background: $btn-color;
			border-radius: 3px;
			display: block;
			position: absolute;
			top: 15%;
			left: 0;
		}
	}
	.woocommerce-notices-wrapper{
		.woocommerce-message{
			@include woo-notices();
		}
	}
	.woocommerce-result-count{
		margin: 0 0 20px;
	}
	.woocommerce-ordering{
		position: absolute;
		top: 0;
		right: 0;
		@media screen and (max-width: $tab){
			right: 0;
		}
		@media screen and (max-width: $phone){
			right: 0;	
		}
	}
	
	//製品一覧
	.products{
		width: 100%;
		@include flex();
		justify-content: flex-start;
		> li.product{
			width: 31%;
			height: auto;
			background: $gray;
			margin: 3.5% 3.5% 0 0;
			@media screen and (min-width: $phone + 1px){
				&:nth-child(-n+3){
					margin-top: 0;
				}
				&:nth-child(3n+3){
					margin-right: 0;
				}
			}
			@media screen and (max-width: $phone){
				width: 48.5%;
				margin: 3% 3% 0 0;
				&:nth-child(-n+2){
					margin-top: 0;
				}
				&:nth-child(2n+2){
					margin-right: 0;
				}
			}
			> a{
				width: 100%;
				display: block;
				height: auto;
				color: $normal;
				line-height: 1.5;
				position: relative;
				.onsale{
					position: absolute;
					right: 0;
					top: 0;
					padding: 8px 15px;
					background: $btn-color;
					color: $normal;
					text-align: center;
					z-index: 2;
				}
				img{
					width: 100%;
					height: auto;
				}
				h2{
					font-size: 22px;
					padding: 12px 15px 8px;
					@media screen and (max-width: $tab){
						font-size: 18px;	
					}
				}
				.star-rating{
					padding: 0 15px 12px;
					.rating{
						color: $star-color;
					}
				}
				.price{
					display: block;
					padding: 0 15px 12px;
					line-height: 1;
					font-size: 15px;
					del{
						color: $btn-color;
						display: inline-block;
						margin: 0 12px 0 0;
					}
					ins{
						background: none;
						color: $normal;
					}
				}
			}
			a.button{
				background: none;
				border: none;
				border-top: $border 1px solid;
				padding: 12px 15px;
				margin: 0;
				&.add_to_cart_button{
					&:before{
						content: "\f217";
						font-weight: 900;
                        font-family: "Font Awesome 5 Free";
						display: inline-block;
						margin: 0 8px 0 0;
						color: $btn-color;
					}
				}
				&:hover{
					background: $gray;
				}
			}
			.added_to_cart{
				padding: 4px 15px 15px;
			}
		}
	}
	
	//製品詳細
	.product{
		@include flex();
		position: relative;
		$itemsize: 320px;
		@media screen and (max-width: $phone){
			display: block;	
		}
		.onsale{
			position: absolute;
			top: 0;
			right: 0;
			background: $btn-color;
			padding: 4px 16px;
			color: #FFF;
		}
		.woocommerce-product-gallery{
			width: $itemsize;
			opacity: 1 !important;
			@media screen and (max-width: $phone){
				width: calc(100vw - 30px);	
			}
			img{
				width: 100%;
				height: auto;
			}
			#largeimg{
				width: 100%;
				height: $itemsize;
				background-position: center;
				background-repeat: no-repeat;
				background-size: cover;
				margin: 0 0 10px;
				@media screen and (max-width: $phone){
					height: calc(100vw - 30px)
				}
			}
			
			&__wrapper{
				@include flex();
				justify-content: flex-start;
			}
			&__image{
				width: 32%;
				margin: 2% 2% 0 0;
				&:nth-child(3n+3){
					margin-right: 0;
				}
				&:nth-child(-n+3){
					margin-top: 0;
				}
			}
		}
		.summary{
			width: calc(100% - #{$itemsize});
			padding: 0 0 0 48px;
			@media screen and (max-width: $phone){
				width: auto;
				padding: 20px 0 0;
			}
			.product_title{
				font-size: 32px;
				padding: 0 0 8px;
				margin: 0 0 20px;
				border-bottom: $border 2px solid;
			}
            .price{
                display: block;
                padding: 0 0 12px;
                line-height: 1;
                font-size: 24px;
                del{
                    color: $btn-color;
                    display: inline-block;
                    margin: 0 12px 0 0;
                }
                ins{
                    background: none;
                    color: $normal;
                }
            }
			.woocommerce-product-details{
				&__short-description{
					padding: 15px;
					background: $gray;
					border-radius: 3px;
					margin: 12px 0 16px;
				}
			}
			.cart{
				margin: 16px 0 0;
                @include flex();
                align-items: center;
                justify-content: flex-start;
				.quantity{
					@include flex();
					align-items: center;
					justify-content: flex-start;
					margin: 0 20px 0 0;
					&.hidden{
						display: none;
					}
					.screen-reader-text{
						display: inline-block;
						margin: 0 20px 0 0;
					}
					.input-text{
						width: 80px;
						display: inline-block;
					}
				}
				.single_add_to_cart_button{
					border: none;
					color: #FFF;
					background: $btn-color;
					font-size: 16px;
					padding: 6px 24px;
					border-radius: 2px;
					cursor: pointer;
					&:hover{
						background: darken($btn-color,4%);
					}
				}
			}
			.product_meta{
				margin: 24px 0 0;
				a{
					text-decoration: underline;
				}
			}
		}
		.woocommerce-tabs{
			width: 100%;
			margin: 40px 0 0;
			.wc-tabs{
				@include flex();
				align-items: center;
				justify-content: flex-start;
				li{
					margin: 0 2px 0 0;
					padding: 6px 24px;
					background: $dark-color;
					color: #FFF;
					a{
						color: #FFF;
					}
				}
			}
			.woocommerce-Tabs-panel{
				border: $border 1px solid;
				padding: 24px;
				background: $gray;
				@media screen and (max-width: $phone){
					padding: 15px;	
				}
				h2{
					font-size: 16px;
					font-weight: 700;
					margin: 0 0 8px;
				}
				table{
					border: $border 1px solid;
					border-collapse: collapse;
					width: 100%;
					background: #FFF;
					tbody{
						tr{
							border-bottom: $border 1px solid;
							&:last-child{
								border-bottom: none;
							}
							th,td{
								vertical-align: bottom;
								text-align: left;
								padding: 20px;
							}
							th{
								width: 200px;
							}
							td{
								
							}
						}
					}
				}
				.woocommerce-noreviews{
					text-align: center;
					padding: 8px 0;
					background: $border;
				}
				.comment-reply-title{
					color: $normal;
				}
				.required{
					color: $btn-color;
				}
				.commentlist{
					> li{
						margin: 20px 0;
						background: $border;
						padding: 20px;
						.comment_container{
							@include flex();
							justify-content: flex-start;
							img{
								width: 60px;
							}
							.comment-text{
								width: calc(100% - 60px);
								padding: 0 0 0 20px;
								font-size: 13.5px;
								.rating{
									color: $normal;
									font-size: 16px;
									display: inline-block;
									margin: 0 4px;
								}
								.description{
									margin: 12px 0 0;
									padding: 12px 0 0;
									border-top: $border 1px solid;
								}
							}
						}
					}
				}
				#review_form_wrapper{
					margin: 24px 0 0;
					.comment-reply-title{
						font-size: 15px;
						font-weight: 700;
					}
					.comment-form-rating,
					.comment-form-comment,
					.comment-form-author,
					.comment-form-email{
						margin: 20px 0 0;
						@include flex();
						label{
							width: 100px;
						}
						.stars,
						select,
						input,
						textarea{
							width: calc(100% - 100px);
						}
					}
					.stars{
						.star-1,
						.star-2,
						.star-3,
						.star-4,
						.star-5{
							display: inline-block;
							margin: 0 8px 0 0;
							color: $normal;
							&:before{
								content: "\f005";
								font-weight: 900;
								font-family: "Font Awesome 5 Free";
								color: $star-color;
								display: inline-block;
								margin: 0 4px 0 0;
							}
							&.active{
								background: $star-color;
								padding: 1px 12px;
								border-radius: 999px;
								&:before{
									color: $normal;
								}
							}
						}
					}
					.comment-form-cookies-consent{
						margin: 20px 0 0;
						@include flex();
						align-items: center;
						justify-content: flex-start;
						input{
							margin: 0 8px 0 0;
						}
					}
					.form-submit{
						text-align: center;
						margin: 32px 0 0;
						input{
							display: inline-block;
							color: #FFF;
							background: $btn-color;
							border: none;
							font-size: 18px;
							width: 320px;
							padding: 12px 0;
							cursor: pointer;
							border-radius: 3px;
							&:hover{
								background: darken($btn-color,4%);
							}
						}
					}
				}
			}
		}
		.related{
			margin: 40px 0 0;
			> h2{
				@include woo-h();
				width: 100%;
			}
		}
	}
	
	.woocommerce-thankyou-order-received{
		text-align: center;
		font-size: 17px;
		margin: 0 0 24px;
		@media screen and (max-width: $phone){
			font-size: 15px;
			margin: 0 0 16px;
		}
	}
	.woocommerce-thankyou-order-details.order_details{
		background: $gray;
		color: $normal;
		@include flex();
		align-items: center;
		justify-content: flex-start;
		padding: 15px;
		@media screen and (max-width: $phone){
			display: block;	
		}
		> li{
			@media screen and (max-width: $tab){
				margin: 8px;
			}
			@media screen and (max-width: $phone){
				margin: 0 0 15px;
				padding: 0 0 15px;
				border: none;
				border-bottom: $border 1px solid;
				float: none;
				&:last-child{
					margin: 0;
					padding: 0;
					border: none;
				}
				strong{
					display: inline-block;
					margin: 0 0 0 8px;
				}
			}
		}
		+ p{
			margin: 0 0 32px;
			font-size: 17px;
		}
	}
	.woocommerce-column--billing-address{
		@media screen and (max-width: $phone){
			margin: 0 0 20px;	
		}
	}
	.woocommerce-order-details{
		&__title{
			@include woo-h;
		}
	}
	.woocommerce-column{
		&__title{
			@include woo-h;
			
		}
		address{
			background: $border;
			padding: 15px;
			margin: 0;
		}
	}
	.woocommerce-product-rating{
		strong.rating{
			color: $star-color;
		}
	}
}