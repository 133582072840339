#site-about{
	.txt{
		@include min();
		text-align: center;
		font-size: 17px;
		letter-spacing: .08em;
		line-height: 1.8;
		margin: 0 0 56px;
		@media screen and (max-width:$phone){
			@include font();
			font-size: 12px;
			margin: 0 $mrg-sp $mrg-sp*2;
			text-align: justify;
			br{
				display: none;
			}
		}
		dt{
			font-size: 32px;
			margin: 0 0 24px;
			letter-spacing: .12em;
			line-height: 1;
			@media screen and (max-width:$phone){
				@include min();
				font-size: 20px;
				text-align: center;
				margin: 0 0 16px;
			}
		}
	}
	.flexer{
		@include flex();
		figure{
			width: calc(50% - #{$mrg/2});
			@media screen and (max-width:$phone){
				width: calc(50% - #{$mrg-sp/2});
			}
			img{
				width: 100%;
			}
		}
	}
	
	h2{
		text-align: center;
		@include min();
        font-size: 15px;
        margin: 0 0 32px;
        letter-spacing: .12em;
        line-height: 1;
		@media screen and (max-width:$phone){
			margin: 0 0 $mrg-sp*2;	
		}
		&.mb{
			margin: 0 0 56px;
			@media screen and (max-width:$phone){
				margin: 0 0 $mrg-sp*2;	
			}
		}
		&:before{
			@include self;
			content: attr(data-eng);
			font-size: 48px;
			letter-spacing: .12em;
			display: block;
			margin: 0 0 12px;
			@media screen and (max-width:$phone){
				font-size: 28px;
				margin: 0 0 8px;
			}
		}
	}
	
	.prices{
		padding: 0 0 0 50%;
		position: relative;
		@media screen and (max-width:$phone){
			padding: 0;	
		}
		&:before{
			content: '';
			width: 50%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background: url("../../../images/about/photo_03.jpg") no-repeat center;
			background-size: cover;
			@media screen and (max-width:$phone){
				position: relative;
				width: 100%;
				height: 200px;
				display: block;
			}
		}
		.table-style{
			background: #FFF;
			width: 100%;
			border-collapse: collapse;
			tbody{
				tr{
					border-bottom: #f2f2f2 1px solid;
					&:last-child{
						border-bottom: none;
					}
					th,td{
						vertical-align: middle;
						text-align: left;
						padding: 24px 48px;
						font-size: 17px;
						font-weight: 500;
						@media screen and (max-width:$phone){
							padding: $mrg-sp;
							font-size: 15px;
						}
						small{
							font-size: 12px;
						}
					}
					th{
						width: 30%;
						@media screen and (max-width:$phone){
							width: 35%;	
						}
					}
				}
			}
		}
	}
	
	.map{
		iframe{
			vertical-align: bottom;
			filter: grayscale(1);
			@media screen and (max-width:$phone){
				height: 200px;	
			}
		}
	}
	p.acc{
		text-align: right;
		margin: 24px 0 0;
		@media screen and (max-width:$phone){
			text-align: center;	
		}
	}
}